/** @jsx jsx */
import { css, jsx } from "@emotion/react";
// import { useSearchSettings } from "@municipio/gatsby-theme-basic/src/hooks";
import SelectField from "@whitespace/gatsby-plugin-search/src/components/SelectField";
import { normalizeOptions } from "@whitespace/gatsby-plugin-search/src/utils";
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
import { useMenu, useInstantSearch } from "react-instantsearch-hooks-web";

SearchMenuDropdown.propTypes = {
  attribute: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node,
  options: PropTypes.oneOfType([
    PropTypes.objectOf([
      PropTypes.string,
      PropTypes.shape({
        count: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      }),
    ]),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          count: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
          value: PropTypes.number,
        }),
      ]),
    ),
  ]),
  searchAsYouType: PropTypes.oneOf(false),
  submitLabel: PropTypes.node,
};

export default function SearchMenuDropdown(props) {
  let { attribute, label, options, hideLabel, placeholder, ...restProps } =
    props;
  const {
    items,
    // createURL,
    refine,
    // canRefine,
    // isShowingMore,
    // toggleShowMore,
    // canToggleShowMore,
    // sendEvent,
  } = useMenu(props);
  const { results } = useInstantSearch();

  let forwardedOptions;

  if (options) {
    forwardedOptions = normalizeOptions(options).map((item) => {
      let {
        value,
        label,
        count,
        // isRefined,
      } = { ...items.find((i) => i.value === item.value), ...item };
      return {
        value,
        label: typeof label === "function" ? label(item, items) : label,
        count: typeof count === "function" ? count(item, items) : count,
      };
    });
  } else {
    forwardedOptions = items;
  }

  forwardedOptions = forwardedOptions.map(
    ({
      value,
      label,
      count,
      // isRefined,
    }) => ({
      value,
      label: `${label} (${Number(count) || 0})`,
    }),
  );

  const value = items.find((item) => item.isRefined)?.value;
  const name = attribute.replace(/\./g, "_");

  if (!results || results.intercepted) {
    return null;
  }

  return (
    <SelectField
      name={name}
      isMulti={false}
      placeholder={placeholder}
      value={value}
      onChange={(value) => {
        refine(value);
      }}
      options={forwardedOptions}
      {...restProps}
    />
  );
}
