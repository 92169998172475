/** @jsx jsx */
import { jsx } from "@emotion/react";
import { H, Section } from "@jfrk/react-heading-levels";
import ContentTypeArchive from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/ContentTypeArchive";
import PropTypes from "prop-types";

import SEO from "../../../components/seo";
import Wrapper from "../../../Wrapper";

// import { useTranslation } from "react-i18next";

ContentTypeTemplate.propTypes = {
  pageContext: PropTypes.shape({
    contentType: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default function ContentTypeTemplate({
  pageContext: { title, contentType },
}) {
  // const { t } = useTranslation();

  return (
    <Wrapper fullWidth={contentType.name === "event"}>
      <SEO title={title} />
      <H>{title}</H>
      <Section>
        <ContentTypeArchive contentType={contentType} />
      </Section>
    </Wrapper>
  );
}
