/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Grid from "@municipio/gatsby-theme-basic/src/components/Grid";
import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import { getMostRelevantDate } from "@whitespace/gatsby-plugin-search/src/utils";
import PropTypes from "prop-types";
import { useCallback } from "react";
// import { useTranslation } from "react-i18next";
import { useHits, Highlight } from "react-instantsearch-hooks-web";

SearchHits.propTypes = {
  hitComponent: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default function SearchHits({ children, ...props }) {
  let { hits, results, sendEvent } = useHits(props);
  const { hitComponent: Hit } = props;
  const { schema } = useSearch();
  // const { t } = useTranslation();

  const transformHit = useCallback((hit) => {
    // console.log('hit', hit)
    let isFile = hit.contentType?.name === "file";
    return {
      ...hit,
      dates: hit.dates,
      contentType: hit.contentType?.name,
      // contentTypeLabel: t(`contentTypes.${hit.contentType?.name}.name`, {
      //   count: 1,
      // }),
      label: <Highlight attribute={"title"} hit={hit} />,
      text: isFile ? (
        <Highlight attribute={"file.attachment.content"} hit={hit} />
      ) : (
        <Highlight attribute={"textContent"} hit={hit} />
      ),
      url: hit.path,
    };
  }, []);

  hits = hits.map(transformHit);
  // console.log('results', results)
  // console.log('hits', hits)
  if (typeof children === "function") {
    return children({ hits, results, sendEvent });
  }

  return (
    <Grid
      autoFit={!schema}
      css={css`
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        isolation: isolate;
      `}
    >
      {hits.map((item, index) => {
        return <Hit key={index} hit={item} />;
      })}
    </Grid>
  );
}
