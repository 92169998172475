/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
  PageGrid,
  PageGridLayout,
  PageGridGroup,
  PageGridItem,
} from "@whitespace/components";

export default function Wrapper({ fullWidth, children }) {
  return fullWidth ? (
    <PageGridLayout
      css={css`
        --page-grid-wrapper-max-width: 1312px;
        @media (min-width: 480px) {
          --page-grid-layout-gap: var(--layout-padding, 0.5rem);
        }
      `}
    >
      <PageGrid
        css={css`
          margin-top: 1rem;
          margin-bottom: 2rem;
        `}
      >
        <PageGridGroup>
          <PageGridItem colSpan={12}>{children}</PageGridItem>
        </PageGridGroup>
      </PageGrid>
    </PageGridLayout>
  ) : (
    <PageGrid
      css={css`
        margin-top: 1rem;
        margin-bottom: 2rem;
        padding-left: var(--layout-mobile-padding, 0.5rem);
        padding-right: var(--layout-mobile-padding, 0.5rem);
      `}
    >
      <PageGridItem>{children}</PageGridItem>
    </PageGrid>
  );
}
