import { getMostRelevantDate } from "@whitespace/gatsby-plugin-search/src/utils";
import { WPContentNodeCard } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import PropTypes from "prop-types";
import React from "react";

EventSearchHit.propTypes = {
  hit: PropTypes.object,
};

export default function EventSearchHit(props) {
  let {
    hit: { dates, ...hit },
  } = props;
  const { contentType } = hit;
  return (
    <WPContentNodeCard
      contentNode={{
        ...hit,
        date:
          contentType !== "page"
            ? getMostRelevantDate(
                dates.map((date) => date.formatted),
                // TODO: Add interval from context
              )
            : null,
        theme: hit.theme || "beige",
      }}
    />
  );
}
